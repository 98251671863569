// Printing Styles for USL team sites
@media print {

  #GoogleSearchBoxTopNav,
  #networkDropMenu,
  #sponsorBarContainer,
  #teamLogoContainer,
  #topNavPlaceholder,
  #rightRail,
  #megaFooter,
  .presentedBy,
  #networkDropMenu,
  .menuWrap {
    display: none !important;
  }

}

#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 0 0 0 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 15px;
    font-size: 2.25em;
    font-weight: 600;
    border-bottom: $borderWidth solid $borderColor;
    text-transform: initial;
    .newsPage & {
      display: none;
    }
  }

  #yieldContent.row { margin: auto; }

  #panelOne, #panelTwo { padding: 0; }

  .yieldPageContent { padding:0; }
  .has-mobile-nav & {
    width: calc(100vw - 30px);
  }
}

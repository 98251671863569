$site-logo-size: $main-nav-height - 5; //$site-tagline-height + $main-nav-height + $sub-nav-height + 3;
$site-logo-size-fixed: $main-nav-height-fixed; //$site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

#sn-site-logo {
  position: relative;
  width: $site-logo-size;
  height: $site-logo-size;
  padding: 10px 0px;
  background-color: transparent;
  z-index: 1000;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all $transition-linear;
  // margin-right: 15px;

  // .nav-fixed & {
  //   width: $site-logo-size-fixed;
  //   height: $site-logo-size-fixed;
  //   // top: 2px;
  // }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: left bottom;//center center; 
    background-size: contain;
    -webkit-background-size: contain;
  }

  .has-mobile-nav & {
    display: block !important;
    height: 50px;
    padding: 5px;
    width: 50px;
    margin-right: 5px;
  }
  .has-main-nav & {
    top: -53px;
    width: 160px;
    height: 155px;
    padding: 13px 0px 7px 0px;
  }
}

.mobile-tagline-text{
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

body {
  overflow-x: hidden;

}

html {
  padding-bottom: 40px;
}

//TEAM NAME CONTAINER
.team-name-container {
  display: block;
  height: 100px;
  background-color: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0px calc((100vw - 1000px)/2);
  .page-manager-visible & {
    padding: 0px calc((100vw - 1060px)/2); 
  }

  .team-name-text {
    line-height: 1;
    p {
      // font-size: 50px;
      font-weight: 100;
      // padding-left: $site-logo-width;
      padding-left: 140px;
    }
  }
  .has-mobile-nav & {
    display: none;
  }
}

// NAV WRAPPER
.nav-container {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: $dk_gray;
  border-top: 5px solid $accent-color;
  transition: height .2s linear;
  padding: 0px calc((100vw - 1000px)/2);
  .page-manager-visible & {
    padding: 0px calc((100vw - 1060px)/2);
  }

  // .nav-fixed & {
  //     height: 60px;
  // }
  .theme-main-nav {
      display: flex;
      align-items: center;

      .theme-nav {
          line-height: 30px;
      }
  }
  .search-container {
      height: 23px;
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .theme-search-box{
        &#PageSearchBoxTopNav{
          display: flex;
          .theme-search-input{
            display: none;
          }
          .theme-search-submit{
            color: #FFF;
            background-color: transparent;
            padding: 0px;
            font-size: 0px;
          }
        }
      }
      .search-icon {
          height: 100%;
          width: 102%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 100;
              &:before {
              content: "\f002";
              display: block;
              font-family: FontAwesome;
              color: $lt_gray;
              font-size: 16px;
          }

      }
      &:hover {
          cursor: pointer;
          .search-icon {
              &:before {
                  color: $primary-color;
              }
          }
      }
      .has-mobile-nav & {
        display: none !important;
      }
  }
  .has-mobile-nav & {
      height: 50px;
      padding: 0px;
      width: calc(100vw + 1px);
      .nav-sponsor-container {
        display: none;
      }
  }
  .has-main-nav & {
    .mobile-tagline-text {
      display: none;
    }
    .custom-toggle {
      display: none;
    }
  }
  .has-mobile-nav & {
    background-color: $primary-color;
  }
}

//HOME STYLES
.home {
  #siteContainer {
    #displayBodyHeader:not(.displayBodyHeader-image) {
      display: none;
    }
  } 
  &.user_mode {
    .theme-sub-nav {
      display: none;
    }
  }
}

//Assets - Leaderboard Ad
.global-leaderboard-container {
  min-height: 90px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px calc((100vw - 1000px)/2);
  .page-manager-visible & {
    padding: 0px calc((100vw - 1060px)/2); 
  }
  .pageElement {
    // max-width: 728px;
    margin: 20px 0px;
    max-height: 90px;
    overflow: hidden;
    &.leaderboard-ad, &.leaderboard-graphic {
      min-width: 728px;
      max-width: 728px;
      .has-mobile-nav & {
        min-width: 0px;
      }
    }
    &.leaderboard-sponsor {
      // max-width: 135px;
      max-height: 90px;
      overflow: hidden;
      display: flex;
      flex-flow: column-reverse;
      justify-content: center;
      align-items: center;
      a {
        max-width: 200px;
      }
      img {
        padding-top: 10px;
        max-height: 76px;
        width: auto;
        max-width: 200px;
        display: none;
      }
      p.cutline {
        background-color: transparent;
        font-size: 8px;
        text-align: center;
        font-style: normal;
        padding: 0px;
        color: $dk_gray;
        line-height: 1.2;
        letter-spacing: 1px;
      }
      .has-mobile-nav & {
        display: none;
      }
    }
    .has-mobile-nav & {
      max-width: 100%;
    }
  }
  .has-mobile-nav & {
    min-height: auto;
    img {
      width: calc(100vw - 60px);
    }
  }
}

//Presented By Logo
.nav-sponsor-container {
  // overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  max-width: 150px;
}
.nav-sponsor {
  padding: 5px;
  max-width: 150px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  // &:before {
  //   content: "presented by";
  //   font-size: 7px;
  //   color: white;
  //   font-family: $fontTwo;
  //   text-transform: uppercase;
  //   font-weight: bold;
  //   letter-spacing: 1px;
  // }
  a {
    max-width: 150px;
    height: 75px;
    transition: height .2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    display: none;
    &.tall {
      width: auto;
      height: 100%;
    }
    &.wide {
      width: 100%;
      height: auto;
      max-width: 150px;
    }
  }

}

//Footer Logo
.custom-footer {
  padding: 30px;
  // padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  .sn-site-logo-background {
    height: 100px;
    width: 100px;
    display: block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0px 15px;
  }
  hr {
    background-color: #fff;
    width: calc(100% / 4);
  }
}

//Footer Links
.custom-footer {
  .ajax.footer, .footer-content {
    .textBlockElement {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none !important;
        line-height: 1;
        margin-left: 0;
        li {
          padding: 0px 15px;
          border-right: 1px solid #fff;
          .has-mobile-nav & {
            border: none;
            margin: 5px 0px;
          }
          &:last-of-type {
            border: none;
          }
          a {
            color: #fff;
            font-size: 14px;
            font-weight: 100;
            font-family: $fontOne;
          }
        }
        .has-mobile-nav & {
          flex-flow: column;
        }
      }
    }
  }
}


//Sponsor Scroll
.sponsor-scroll {
  background: #fff;
  padding: 0px 30px;
  border-top: 1px solid $lt-gray;
  .slick-slider {
    .slick-prev:before, .slick-next:before {
      color: $md_gray;
      font-size: 26px;
    }
    .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
    }
    .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
    }
    .slick-slide {
      .heroPhotoElement {
        img {
          padding: 10px;
          max-height: 100px;
          max-width: 200px;
          width: auto;
          filter: grayscale(100);
        }
      }
      &:hover{
        // background-color: $lt_gray;
        img {
          filter: none;
          transition: filter .2s linear;
        }
      }
    }
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }
  .column {
    padding: 0;
  }
  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
  .has-mobile-nav & {
    img {
      max-height: 100px;
      width: auto;
    }
  }
}

//Site Guide Accordion
  .accordion-header {
    h3 {
      border-bottom: 2px solid $secondary-color;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:after {
        content: "\f107";
        color: $primary-color;
        display: inline-block;
        position: relative;
        font-size: 18px;
        font-family: FontAwesome;
        float: right;
        padding-right: 15px;
        padding-left: 15px;
        .active & {
          transform:rotateX(180deg);
          color: $secondary-color;
        }
      }
    }
}
.accordion-content {
  h3 {
    border: none;
  }
}

//Sponsor Text Block

.user_mode {
  .sponsored, .sponsored-content {
    margin-top: 10px;

    .textBlockElement {

      .text {

        &.sponsored-text {

          .sponsorBox { top: 10px; }

        }

        .sponsorBox { margin-right: 15px; }
      }

      h3 {
        position: relative;
        // padding-right: 250px;
      }

      .theSponsor {
        display: inline-block;
        height: 100%;

        img{
          height: 100%;
          width: auto !important;
        }

      }

      .sponsorBox {
        height: 30px;
        position: absolute;
        right: 0;
        bottom: 5px;
        display: inline-block;

        &:before {
          content: 'Presented by';
          display: inline-block;
          position: absolute;
          line-height: 50px;
          color: $dk_gray;
          font-family: $fontTwo;
          font-size: 12px;
          margin-right: 10px;
          right: 100%;
          width: 75px;
          font-weight: 400;
          text-transform: none;
        }

      }

    }

  }
}

//media slider
.sn-media-slider {
  .flexslider {
    .slider-pagination {
      height: 20px;
    }
  }
}

//Desktop Only
.has-mobile-nav {
  .desktop-only {
   display: none;
  }
}

// DFP LEADERBOARD
div#adWrapperTopLeaderboard {
  margin-top: -125px;
}

//Load Image
// body.home:not(.newsPage) {
//   opacity: 0;
//   transition: opacity .2s linear;
//   background-image: url($team-load-image);
//   background-color: $primary-color;
//   background-position: calc(50vw - (134px/2))  calc(50vh - (150px/2));
//   //team load image width: 134px;
//   //team load-image height: 150px;
//   background-repeat: no-repeat;
//   overflow: hidden;
// }

//Hide Sponsor Scroller
#hide-sponsors {
  .global-sponsors-container {
    display: none;
  }
}

//Account Nav Fix
.has-mobile-nav {
  .logged_in {
    #accountNav {
      & > li.home{
        display: block;
      }
   }
  }
}

//hide home and its sub pages in mobile
.zeroVisability {
  visibility: hidden !important;
}

//Google Translate
.goog-te-gadget-simple .goog-te-menu-value span {
  text-decoration: none !important;
}

#google_translate_element {
  .has-mobile-nav & {
    display: none;
  }
}

//card agg 
.card-agg {
  .newsAggregatorElement {
    .condensed > a {
      text-decoration: none;
      padding: 0px;
    }
    .item:not(.extendedOptions) {
      padding: 0px;
      border: none;
      img {
        border: none;
        width: 100%;
        height: auto;
      }
      .details {
        padding-left: 0px;
        background: $link-color;
        padding: 15px;
        &:hover {
          text-decoration: none;
        }
        li {
          h4 {
            a {
              font-family: $fontOne;
              font-weight: 700;
              line-height: 1.125;
              color: #fff;
              font-size: 24px;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      .teaser {
        color: #fff;
        font-size: 12px;
        font-style: italic;
      }
      .dateAuthor {
        span {
          color: #fff;
        }
      }
    }
    .extendedOptions {
      display: none;
    }
  }
}

.textBlockElement {
  h3 {
    @include special-header;
  }
}

//Favicon Img Tag Fix for Page Elements
.nodePicker-site-name img {
  display: none !important;
}

// 

.legalLink[href="/help"] {
  display: none !important;
}

.user_mode {
  &.right-rail-layout {
    #panelTwo {
      width: calc(100% - 330px);
      max-width: 1085px;
      float: left;
    }
    #panelThree {
      width: 100%;
      max-width: 330px;
      float: left;
      padding-right: 15px;
      .right-rail {
        width: 100%;
        max-width: 315px;
        float: right;
        .column {
          padding: 0;
        }
      }
    }
    @media screen and (max-width: 768px){
      #panelTwo {
        width: 100%;
        max-width: 100%;
      }
      #panelThree {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
      }
    }
  }
}
.edit_mode {
  .right-rail {
    display: none;
  }
}
